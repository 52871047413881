































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import UserService from "@/services/UserService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { EditorControlView, AlertDialogView },
    data: () => ({
        user: {},
        updatePassword: false,
        password: "",
        confirmPassword: "",
        saving: false
    })
})
export default class UserInfoView extends Mixins(InfoViewMixin) {
    private editorControl = new EditorControlProperties();
    private alertDialog = new AlertDialogProperties();
    private userService = new UserService();

    public get genders() {
        return [
            {
                text: this.$t("text.male"),
                value: "Male"
            },
            {
                text: this.$t("text.female"),
                value: "Female"
            }
        ];
    }

    public created() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

    public async load() {
        try {
            if (this.isCreate()) {
                this.$data.user = { claims: [], usernameEditable: false };
                this.$data.password = "";
                this.$data.confirmPassword = "";
            } else if (this.isModify()) {
                var id = this.$route.query.id;
                const r = await this.userService.get({ id, loadClaims: true });
                if (r.data.users.length === 0) {
                    throw new Error(this.$t("message.record-not-found").toString());
                }
                const user = r.data.users[0];
                user.facebookLinked = user.facebookAccountId != null;
                if (!user.claims) user.claims = [];
                if ((user.username ?? "") === "") user.usernameEditable = true;

                user.administrator = false;
                const claims = user.claims;
                for (var i = 0; i < claims.length; i++) {
                    var tempId = Math.random();
                    if (!claims.find(x => x.tempId === tempId)) {
                        claims[i].tempId = tempId;
                        if (claims[i].type === 'User-Type' &&
                            claims[i].value === 'Administrator') {
                            user.administrator = true;
                        }
                    } else {
                        i--;
                    }
                }
                this.$data.user = user;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async create() {
        await this.$router.push("/management/common/users/create");
        this.mode = "create";
        await this.load();
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const user = this.$data.user;
            const updatePassword = this.$data.updatePassword;
            const password = this.$data.password;
            const confirmPassword = this.$data.confirmPassword;
            const claims: any[] = user.claims;

            console.log(user);

            if (!user.administrator) {
                for (var i = 0; i < claims.length; i++) {
                    if (claims[i].type === 'User-Type' && 
                        claims[i].value === 'Administrator') {
                        claims.splice(i);
                        break;
                    }
                }
            } else {
                var exist = false;
                for (var i = 0; i < claims.length; i++) {
                    if (claims[i].type === 'User-Type' && 
                        claims[i].value === 'Administrator') {
                        exist = true;
                        break;
                    } 
                }
                if (!exist) {
                    claims.push({ type: 'User-Type', value: 'Administrator' });;
                }                
            }

            if (this.isCreate()) {
                const r = await this.userService.post(user, password, confirmPassword);
                r.data.facebookLinked = user.facebookAccountId != null;
                if (!r.data.claims) {
                    r.data.claims = [];
                }
                if ((r.data.username ?? "") === "") {
                    r.data.usernameEditable = true;
                }
                r.data.administrator = false;
                const claims = r.data.claims;
                for (var i = 0; i < claims.length; i++) {
                    var tempId = Math.random();
                    if (!claims.find(x => x.tempId === tempId)) {
                        claims[i].tempId = tempId;
                        if (claims[i].type === 'User-Type' &&
                            claims[i].value === 'Administrator') {
                            r.data.administrator = true;
                        }
                    } else {
                        i--;
                    }
                }
                this.$data.user = r.data;
                this.$data.password = "";
                this.$data.confirmPassword = "";

                await this.$router.push("/management/common/users/modify?id=" + this.$data.user.id);
                this.mode = "modify";

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            } else if (this.isModify()) {
                const r = await this.userService.put(user, true, updatePassword, password, confirmPassword);
                r.data.facebookLinked = r.data.facebookAccountId != null;
                
                if (!r.data.claims) r.data.claims = [];
                if ((r.data.username ?? "") === "") r.data.usernameEditable = true;

                r.data.administrator = false;
                const claims = r.data.claims;
                for (var i = 0; i < claims.length; i++) {
                    var tempId = Math.random();
                    if (!claims.find(x => x.tempId === tempId)) {
                        claims[i].tempId = tempId;
                        if (claims[i].type === 'User-Type' &&
                            claims[i].value === 'Administrator') {
                            r.data.administrator = true;
                        }
                    } else {
                        i--;
                    }
                }
                         
                this.$data.user = r.data;
                this.$data.updatePassword = false;
                this.$data.password = "";
                this.$data.confirmPassword = "";
                
                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async remove() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.userService.delete(this.$data.user);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "create") {
            await this.create();
        } else if (button === "save") {
            await this.save();
        } else if (button === "cancel") {
            this.cancel();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "discard-changes" && button === this.$t("text.yes")) {
                await this.load();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted") {
                await this.$router.push("/management/common/users");
            }
        }
    }
}
